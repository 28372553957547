import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Context } from "../helpers/Context";
//import FixedMessenger from "./FixedMessenger";
import { Button } from 'reactstrap';
import { fieldRequired } from "../helpers/validation";
import { set } from "date-fns";
import { getAuthToken } from "../helpers/authentication";

const OrderCompletion = () => {
  const navigate = useNavigate();
  const { getUserAddress, userAddresses, postNewAddress, processPayment } = useContext(Context);
  const [formData, setFormData] = useState({
    company: '',
    address: '',
    apartment: '',
    suburb: '',
    city: '',
    country: '',
    province: '',
    postalCode: '',
    mobileNumber: '',
    instructions: '',
  });
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addNewAddress, setAddNewAddress] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [IsCompleting, setIsCompleting]= useState(false)

  const provinces = [
    { id: 0, abbreviation: '', name: '-SELECT-' },
    { id: 1, abbreviation: 'EC', name: 'Eastern Cape' },
    { id: 2, abbreviation: 'FS', name: 'Free State' },
    { id: 3, abbreviation: 'GP', name: 'Gauteng' },
    { id: 4, abbreviation: 'KZN', name: 'KwaZulu-Natal' },
    { id: 5, abbreviation: 'LP', name: 'Limpopo' },
    { id: 6, abbreviation: 'MP', name: 'Mpumalanga' },
    { id: 7, abbreviation: 'NC', name: 'Northern Cape' },
    { id: 8, abbreviation: 'NW', name: 'North West' },
    { id: 9, abbreviation: 'WC', name: 'Western Cape' },
  ];

  const nearSouthAfricaCountries = [
    { id: 0, abbreviation: '', name: '-SELECT-' },
    { id: 1, abbreviation: 'BW', name: 'Botswana' },
    { id: 2, abbreviation: 'LS', name: 'Lesotho' },
    { id: 3, abbreviation: 'MZ', name: 'Mozambique' },
    { id: 4, abbreviation: 'NA', name: 'Namibia' },
    { id: 5, abbreviation: 'SZ', name: 'Eswatini' },
    { id: 6, abbreviation: 'ZW', name: 'Zimbabwe' },
    { id: 7, abbreviation: 'ZA', name: 'South Africa' },
  ];

  const handleAddressChange = (address) => {

    // var selectedAddressData = address;

    setFormData({ ...address });
    setSelectedAddress(address);
    setAddNewAddress(false);
  };

  const handleNewAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddNewAddressChange = () => {
    setFormData({
      company: '',
      address: '',
      apartment: '',
      suburb: '',
      city: '',
      country: '',
      province: '',
      postalCode: '',
      mobileNumber: '',
    })
    setSelectedAddress('');
    setAddNewAddress(true);
  };

  //const handleChange = (e) => {
  //    const { name, value, type, checked } = e.target;
  //    setFormData((prevData) => ({
  //        ...prevData,
  //        [name]: type === 'checkbox' ? checked : value,
  //    }));
  //};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getAuthToken()) {
      setIsButtonDisabled(true);
      let addressId;
      let cartId = JSON.parse(localStorage.getItem('cart')).cartId;
      //console.log(cartId)
      if (addNewAddress) {
        var valid = true;
        valid &= fieldRequired(formData.address, 'tbAddressError', '* required');
        valid &= fieldRequired(formData.city, 'tbCityError', '* required');
        valid &= fieldRequired(formData.suburb, 'tbSuburbError', '* required');
        valid &= fieldRequired(formData.province, 'ddProvinceError', '* required');
        valid &= fieldRequired(formData.postalCode, 'tbPostalCodeError', '* required');
        valid &= fieldRequired(formData.country, 'ddCountryError', '* required');
        valid &= fieldRequired(formData.mobileNumber, 'tbMobileNumberError', '* required');

        //console.log(formData);
        if (valid) {
          addressId = await postNewAddress(formData);
          await processPayment(cartId, addressId, formData.instructions);
          //setIsButtonDisabled(false);
        }
      } else {
        //console.log(selectedAddress, cartId, formData.instructions);
        addressId = selectedAddress.id;
        await processPayment(cartId, addressId, formData.instructions);
        //setIsButtonDisabled(false);

      }
    } else {
      alert("Please login or register to complete order");
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  };

  useEffect(() => {
    document.title = "Order Completion :: Razer";
    getUserAddress();
    setLoading(false);
  }, [])
  //console.log(userAddresses, formData)

  return (
    <>
      <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
      </div>
      <nav className="mb-3 crumbs">
        <Link to="/" className="breadcrumb-active text-light"><small>Home</small>
        </Link>
        <span className="breadcrumb-arrow text-light"><i className="fa-solid fa-angle-right"></i></span>
        <Link to="/checkout" className="breadcrumb-active text-light"><small>Checkout</small>
        </Link>
        <span className="breadcrumb-arrow text-light"><i className="fa-solid fa-angle-right"></i></span>
        <small className="breadcrumb-not-active">Order Completion</small>
      </nav>

      <div className="col-md-8 mx-auto text-light">
        <h1 className="mb-4">Previous Delivery Address</h1>


        {!loading ? userAddresses && userAddresses.map((item, index) => (
          <div key={index} className="d-flex align-items-center justify-content-between p-2">
            <label className="text-light">
              <input
                type="radio"
                name="address"
                value={item.address}
                checked={selectedAddress && selectedAddress.address === item.address}
                onChange={() => handleAddressChange(item)}
              />
              &nbsp;&nbsp;{item.address}, {item.postalCode}
            </label>
          </div>
        )) : <><i className="far fa-spin me-2"></i>Loading Address...</>}
        <div className="d-flex align-items-center justify-content-between p-2">
          <label className="text-light">
            <input
              type="radio"
              name="NewAddress"
              value={0}
              checked={addNewAddress}
              onChange={handleAddNewAddressChange}
            />
            &nbsp;&nbsp;Add new address
          </label>
        </div>
        <hr />
        <h3 className="mb-4">New Delivery Address</h3>
        <form onSubmit={handleSubmit}>
          <div className="row">


            <div className="col-md-3">
              <>
                <div className="form-group">
                  <label className="text-light">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                  <div id='tbAddressError' className='error-message' />
                </div>
                <div className="form-group">
                  <label className="text-light">Company (optional)</label>
                  <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                </div>
                <div className="form-group">
                  <label className="text-light">Suburb</label>
                  <input
                    type="text"
                    name="suburb"
                    value={formData.suburb}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                  <div id='tbSuburbError' className='error-message' />
                </div>
                <div className="form-group text-light">
                  <label className="text-light">Province</label>
                  {/*<input*/}
                  {/*    type="text"*/}
                  {/*    name="province"*/}
                  {/*    value={formData.province}*/}
                  {/*    onChange={handleNewAddressChange}*/}
                  {/*    className="form-control"*/}
                  {/*    placeholder="Type here..."*/}
                  {/*    maxLength="100"*/}
                  {/*    />*/}
                  <DropDownListComponent id='ddProvince' name='province' placeholder='' enabled={addNewAddress} dataSource={provinces} fields={{ text: 'name', value: 'abbreviation' }} floatLabelType='Always' value={formData.province} change={e => setFormData({ ...formData, province: e.value })} />
                  <div id='ddProvinceError' className='error-message' />
                </div>
                <div className="form-group">
                  <label className="text-light">Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                  <div id='tbMobileNumberError' className='error-message' />
                </div>
              </>
            </div>
            <div className="col-md-3">
              <>
                <div className="form-group">
                  <label className="text-light">Apartment (optional)</label>
                  <input
                    type="text"
                    name="apartment"
                    value={formData.apartment}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                </div>
                <div className="form-group">
                  <label className="text-light">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                  <div id='tbCityError' className='error-message' />
                </div>
                <div className="form-group">
                  <label className="text-light">Postal Code</label>
                  <input
                    type="text"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleNewAddressChange}
                    className="form-control"
                    placeholder="Type here..."
                    maxLength="100"
                    disabled={!addNewAddress}
                  />
                  <div id='tbPostalCodeError' className='error-message' />
                </div>
                <div className="form-group">
                  <label className="text-light">Country</label>
                  {/*<input*/}
                  {/*    type="text"*/}
                  {/*    name="country"*/}
                  {/*    value={formData.country}*/}
                  {/*    onChange={handleNewAddressChange}*/}
                  {/*    className="form-control"*/}
                  {/*    placeholder="Type here..."*/}
                  {/*    maxLength="100"*/}
                  {/*    />*/}
                  <DropDownListComponent id='ddCountry' name='country' enabled={addNewAddress} placeholder='' dataSource={nearSouthAfricaCountries} fields={{ text: 'name', value: 'abbreviation' }} floatLabelType='Always' value={formData.country} change={e => setFormData({ ...formData, country: e.value })} />
                  <div id='ddCountryError' className='error-message' />
                </div>

              </>
            </div>
            <div className="col-md-6">
              <div className="form-group mb-3">
                <label className="text-light">Special Instructions</label>
                <textarea
                  name="instructions"
                  value={formData.instructions}
                  onChange={handleNewAddressChange}
                  className="w-100"
                  rows="4"
                ></textarea>
              </div>
              {/*<div className="form-group mb-3">*/}
              {/*    {addNewAddress && (*/}
              {/*        <label>*/}
              {/*            <input*/}
              {/*                type="checkbox"*/}
              {/*                name="saveAddress"*/}
              {/*                checked={formData.saveAddress}*/}
              {/*                onChange={handleNewAddressChange}*/}
              {/*                className="styled"*/}
              {/*            />{' '}*/}
              {/*            Save this address for next time*/}
              {/*        </label>)}*/}
              {/*</div>*/}
              <div className="complete">
                <Button color='dark' size='sm' outline onClick={() => navigate(-1)}>
                  <i className="fas fa-chevron-left mr-2"></i>&nbsp; Back to cart
                </Button>
                <button type="submit" className="checkout-btn" disabled={isButtonDisabled}>
                  Complete Order &nbsp; {isButtonDisabled ? <i className='fas fa-spinner fa-spin ms-2'></i> : <i className="fas fa-chevron-right ml-2"></i>}
                </button>
              </div>
            </div>
          </div>
        </form>
        {/*<FixedMessenger />*/}
      </div>
    </>
  );
};

export default OrderCompletion;