/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useContext, useEffect, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Table, Accordion,  AccordionBody,  AccordionHeader,  AccordionItem, } from 'reactstrap';
import { Context } from "../helpers/Context";
import { ProductCard } from "./ProductCard";
import { getCurrentDomain } from "../helpers/Lookup";
import { getAuthToken, getUserDetails } from "../helpers/authentication";
import classnames from 'classnames';
//import FixedMessenger from "./FixedMessenger";
//import { ProductBrandCard } from "./ProductBrandCard";

function scrollToSection(sectionId) {
  const element = document.getElementById(sectionId);

  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const ProductView = () => {
  const [hover, setHover] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  //const [activeImage, setActiveImage] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [newReview, setNewReview] = useState({ name: getAuthToken() ? `${getUserDetails().firstName} ${getUserDetails().lastName}` : "", rating: null, comment: '' });
  const location = useLocation();
  const { sku } = useParams();
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(null);
  const [CategoryList, SetCategoryList] = useState(null);

  const { handleAddToCart, sendCustomEvent, handleRemoveFromWishlist, handleAddToWishlist, isItemInWishlist, getProducts, subcategories, products, AddProductActivity, getRandomObjectsFromArray, getSubcategories, categories, getReviews, reviews, setReviews, getBrands, brands } = useContext(Context);
  const productData = products.find(item => item.sku === sku);

  const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }

    console.log(id);
  };

  const decrement = () => {
    setQuantity((prevState) => {
      if (prevState === 1) return 1;
      return prevState - 1;
    });
  };
  const increment = () => {
    //setQuantity((prevState) => prevState + 1);
    setQuantity((prevState) => (prevState < productData.stock) ? prevState + 1 : prevState);
  };

  const selectedBrand = brands.find(item => item.slug === "razer");

  const changeImage = (newImage) => {
    setCurrentImage(newImage);
  };



  const showReviews = () => {
    console.log("Show Reviews");
    if (open !== '3')
      toggle('3');
    scrollToSection('reviewSection');
  }

  const addReview = async (e) => {
    var bearer = 'Bearer ' + getAuthToken();
    const data = {
      Id: 0,
      ProductId: productData.id,
      Name: newReview.name,
      Comment: newReview.comment,
      Rating: newReview.rating,
      DateAdded: new Date(),
      AddedBy: getUserDetails().id,
      Status: 1,
    }
    try {
      if (newReview.name && newReview.comment) {
        setLoading(true);
        const response = await fetch('/api/reviews', {
          method: 'POST',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          setReviews([...reviews, newReview]);
          sendCustomEvent("Product Review", "Reviewed A Product", `${productData.title}`)
          setLoading(false);
        } else {

          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setNewReview({ name: `${getUserDetails().firstName} ${getUserDetails().lastName}`, rating: null, comment: '' });
  };

  useEffect(() => {
    document.title = "Product View :: Razer Shop";

    const fetchData = async () => {
      try {
        await getProducts();
        await getSubcategories();
        await getBrands();
        setLoading(false);
        document.title = productData?.title + " :: Razer Shop";
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

  }, [loading]);

  useEffect(() => {
    if (productData) {
      AddProductActivity(productData.id, 1);
      getReviews(productData.id);
      setCurrentImage("/assets/imgs/Razer_wordmark.png")
      const razerProducts = products.filter(item => item.productBrandId === 17)
      const relatedProductList = getRandomObjectsFromArray(razerProducts, 4, productData)
      setRelatedProducts(relatedProductList);

      if (productData.images) {
        let images = productData.images;
        if (productData.images.includes("DynamicData")) images = JSON.parse(productData.images);
        if (Array.isArray(images)) {
          for (let i = 0; i < 1; i++) {
            if (images[i].includes("DynamicData") && images[i].includes("[")) {
              const parsedImage = JSON.parse(images[i]);
              setCurrentImage(getCurrentDomain() + parsedImage[0]);
            } else {
              setCurrentImage(getCurrentDomain() + images[i]);
            }
          };
        } else if (images.includes("https") && images.includes("[")) {
          setCurrentImage(JSON.parse(images.split(", ")[0])[0]);
        } else {
          setCurrentImage(images.split(", ")[0]);
        }
      }
      console.log(productData);
    }
  }, [productData]);

  const productImage = productData && productData.images ? productData.images.includes("DynamicData") ? JSON.parse(productData.images)[0].includes("\\") && JSON.parse(productData.images)[0].includes("[") ? getCurrentDomain() + JSON.parse(JSON.parse(productData.images))[0].replace(/\\/g, "/") : getCurrentDomain() + JSON.parse(productData.images)[0].replace(/\\/g, "/") : productData.images.split(", ")[0] : "/assets/imgs/Razer_wordmark.png";

  const productPrice = (productData && productData.sPrice && productData.dateSPriceEnds && new Date(productData.dateSPriceEnds) >= new Date()) || (productData && productData.sPrice && productData.ongoing) ? productData.sPrice : productData && productData.price;
  const prevProductPrice = (productData && productData.sPrice && productData.dateSPriceEnds && new Date(productData.dateSPriceEnds) >= new Date()) || (productData && productData.sPrice && productData.ongoing) ? productData.price : 0;

  const currentBrand = productData && brands.find(item => item.id === productData.productBrandId);

  let imageUrls = ["/assets/imgs/Razer_wordmark.png"];

  if (productData && productData.images) {
    let images = productData.images;
    if (productData.images.includes("DynamicData")) images = JSON.parse(productData.images);

    if (Array.isArray(images)) {
      imageUrls = images.map((image) => {
        if (image.includes("DynamicData") && image.includes("[")) {
          const parsedImage = JSON.parse(image);
          return parsedImage;
        } else {
          return image;
        }
      });
    } else if (images.includes("https") && images.includes("[")) {
      imageUrls = JSON.parse(images.split(", ")[0]);
    } else {
      imageUrls = images.split(", ");
    }
  }
  //console.log(currentImage);

  const RenderCategoryLink = (CategoryIDs) => {
    if (CategoryIDs === "")
      return <small className="text-muted">No Categories</small>

    CategoryIDs = JSON.parse(CategoryIDs);
    return (
      <><span className="mx-1 text-light">|</span>{CategoryIDs?.map(GetCategorySlug)}</>
    )
  }

  const GetCategorySlug = (id, idx) => {
    const cat = subcategories.find(c => c.id == id);
    return cat?.title && cat?.slug && <span key={idx}><Link style={{ color: "#44D62C" }} key={idx} to={"/categories/razer/" + (cat?.slug)}>{cat?.title}</Link><span className="mx-1 text-light">|</span></span>
  }

  const RenderTagLink = (TagLink) => {

    let tagArr = [];
    if (TagLink !== "")
      tagArr = TagLink.split(",");
    return (
      tagArr.length > 0 ?
        <><span className="mx-1 text-light">|</span>
          {
            tagArr.map((item, idx) =>
              <span key={idx}>
                <Link style={{ display: "inline-block", color: "#44D62C" }} key={idx} to={"/categories/razer/" + item.trim().replace(/\s/g, "_").toLowerCase()}>{item.trim()}</Link><span className="mx-1 text-light">|</span>
                {(idx === 4 && tagArr.length > 5) && <><br /> <span className="mx-1">|</span></>}
              </span>
            )
          }
        </>
        :
        <small className="text-muted">No tags</small>
    )
  }

  return (
    <>
      <div className="background-container"></div>
      {!loading ? (
        <div className="product-detail">
          <nav className="mb-3 crumbs">
            <Link to="/" className={location.pathname.startsWith("/products") ? "breadcrumb-active text-light" : "breadcrumb-not-active"}><small>Products</small></Link>
            <span className="breadcrumb-arrow text-light"><i className="fa-solid fa-angle-right"></i></span>
            <small className={location.pathname === "/products/product-view" ? "breadcrumb-active" : "breadcrumb-not-active"}>{productData?.title}</small>
          </nav>

          <div className="row ">
            <div className="col-3 col-md-2 mb-3">
              {imageUrls.map((imageUrl, index) => (
                <div key={index} className={`preview-image ${currentImage === (imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl) ? 'active' : ''}`} onClick={() => changeImage(imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl)}>
                  <img src={imageUrl.includes("DynamicData") ? getCurrentDomain() + imageUrl : imageUrl} className="img-fluid" alt="" />
                </div>
              ))}
            </div>
            {/*<div className="col-9 col-md-10">*/}
            {/*  <div className="row">*/}
                <div className="col-9 col-md-6 mb-3">
                  <img src={currentImage} className="img-fluid" />
                </div>
                <div className="col-12 col-md-4">
                  <h1>{productData.title}</h1>

                  <div className="price">
                  {prevProductPrice > 0 ? <del className="text-white">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(prevProductPrice)}</del> : ""}
                    {new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(productPrice)}
                    <div><small className="text-light">availability: {productData.stock > 0 ? <span style={{ color: "#44D62C" }}>In Stock</span> : <span className="text-danger">Out Of Stock</span>}</small></div>
                  </div>

                  <span className="brand-desc"><div dangerouslySetInnerHTML={{ __html: productData.shortDescription.replace(/\\n/g, '') }} /></span>

                  <div className="text-white mb-5">
                    {reviews.length ? <span className="mb-3">< i className="fa-solid fa-star" style={{ color: "#44D62C", fontSize: "16px" }}></i>&nbsp; <strong>{productData.avgRating}</strong>&nbsp; <strong style={{ cursor: "pointer", color: "#44D62C" }} onClick={() => { showReviews() }}>{productData.reviews} {productData.reviews > 1 ? "REVIEWS" : "REVIEW"}</strong></span>
                      : <span className="mb-3"><small>Be the first to</small> <strong style={{ cursor: "pointer", color: "#44D62C" }} onClick={() => { showReviews() }}>REVIEW</strong></span>}
                  </div>

                  {productData.stock > 0 ?
                    <button className="btn-add-to-cart" onClick={() => { handleAddToCart({ product: productData, productId: productData.id, price: productPrice, img: productImage }, quantity); setQuantity(1); }} >
                      ADD TO CART
                    </button> :
                    <button className="btn-add-to-cart disabled">OUT OF STOCK</button>}
                </div>
              </div>
          {/*  </div>*/}
          {/*</div>*/}


          <div id="reviewSection" className="mb-5"></div>
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="1" className="dark-header">Description<i className={`fa-solid ms-2 ${open === '1' ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i></AccordionHeader>
              
              <AccordionBody accordionId="1">
                {productData.about && <>
                  
                    <div dangerouslySetInnerHTML={{ __html: productData.about.replace(/\\n/g, '') }} />
                  </>}
              </AccordionBody>
            </AccordionItem>

            <AccordionItem>
              <AccordionHeader targetId="2" className="dark-header">Specifications <i className={`fa-solid ms-2 ${open === '2' ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i></AccordionHeader>
              <AccordionBody accordionId="2">
                <Table responsive  className="table-text">
                  <tbody>

                    <tr>
                      <td className="w-25">SKU</td>
                      <td>{productData.sku}</td>
                    </tr>
                    <tr>
                      <td>GTIN</td>
                      <td>{productData.gtin}</td>
                    </tr>
                    <tr>
                      <td>Width</td>
                      <td>{productData.width}</td>
                    </tr>
                    <tr>
                      <td>Height</td>
                      <td>{productData.height}</td>
                    </tr>
                    <tr>
                      <td>Weight</td>
                      <td>{productData.weight}</td>
                    </tr>
                    <tr>
                      <td>Length</td>
                      <td>{productData.length}</td>
                    </tr>
                  </tbody>
                </Table>
                {productData.additionalInfo && <>
                  
                    <div dangerouslySetInnerHTML={{ __html: productData.additionalInfo }} />
                  </>}

              </AccordionBody>
            </AccordionItem>

            <AccordionItem>
              <AccordionHeader targetId="3" className="dark-header">Reviews <i className={`fa-solid ms-2 ${open === '3' ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i></AccordionHeader>
              <AccordionBody accordionId="3">
                <Row>
                  <Col sm="6">
                    <Card body style={{ backgroundColor: "#555" }}>
                      <CardTitle>Review Product</CardTitle>
                      {!reviews.find(item => getAuthToken() && item.addedBy === getUserDetails().id) ? <><div className="rating">
                        {[...Array(5)].map((star, index) => {
                          const currentRating = index + 1;
                          return (<label key={index}>
                            <input type="radio" name="rating" value={currentRating} onClick={() => setNewReview({ ...newReview, rating: currentRating })} />
                            <i className="fa-solid fa-star fs-2 star" style={{ color: currentRating <= (hover || newReview.rating) ? "#44D62C" : "#e4e5e9" }} onMouseEnter={() => setHover(currentRating)} onMouseLeave={() => setHover(null)} ></i>
                          </label>);
                        })}
                      </div>
                        <div className="mb-3">
                          <label className="text-light">Comment:</label>
                          <br />
                          <textarea
                            value={newReview.comment}
                            className="w-100"
                            onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
                          />
                        </div>
                        <button className="brand-hero-button w-100" onClick={() => { getAuthToken() ? addReview() : alert("Please log in to review product") }}>Rate</button></> : <CardText>You already reviewed this product.</CardText>}
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card body style={{ backgroundColor: "#555" }}>
                      <CardTitle>Customer Reviews</CardTitle>
                      <div className="reviews-container">
                        {reviews.length ? (reviews.map((review, index) => {
                          const stars = Array.from({ length: review.rating }, (_, i) => i + 1);

                          return (
                            <div key={index} className="customer-review">
                              <div className="rating">
                                {stars.map((starIndex) => (
                                  <span key={starIndex} className="star">&#9733;</span>
                                ))}
                              </div>
                              <div className="customer-info">
                                <p className="customer-name">{review.name}</p>
                                <p className="review-date">{new Date(review.dateAdded).toLocaleDateString()}</p>
                              </div>
                              <p className="review-comment">{review.comment}</p>
                            </div>
                          );
                        })
                        ) : <CardText>No reviews at the moment for this product, be the first to pull the trigger.</CardText>}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </Accordion>


          <hr />
          <h2 className="text-light">Related Products</h2>
          <div className="row px-3 mb-3">
            {relatedProducts.map((product) => {
              return (
                <div className="col-md-6 col-lg-3 d-flex justify-content-center mb-3" key={product.id}>
                  <ProductCard
                    product={product}
                    id={product.id}
                    status={product.stock}
                    accentColour={selectedBrand.accentBrandColour}
                    mainColour={selectedBrand.secondaryBrandColor}
                    cardColour={selectedBrand.primaryBrandColour}
                    textColour={selectedBrand.textColour}
                    imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Razer_wordmark.png"}
                    currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                    prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                    title={product.title}
                    description={product.shortDescription}
                  />
                </div>
              )
            })}
          </div>
          
        </div>) : <div className='text-white text-center'>Loading....</div>}
      {/* <FixedMessenger />*/}
    </>
  );
};

export default ProductView;