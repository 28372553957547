import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../helpers/Context";
import CartItem from "./CartItem";

const Cart = () => {
  const [couponCode, setCouponCode] = useState('');
  const navigate = useNavigate();
  const { cartItems, setShowCart, cartSubTotal, cart, applyCoupon, couponError, appliedCoupon } = useContext(Context);


  return (
    <div className="cart-panel">
      <div
        className="opac-layer"
        onClick={() => setShowCart(false)}
      ></div>
      <div className="cart-content">
        <div className="cart-header">
          <span className="heading">Shopping Cart</span>
          <span className="close-btn" onClick={() => setShowCart(false)} >
            <i className="fa-solid fa-xmark"></i>
            <span className="text">close</span>
          </span>
        </div>

        {!cartItems.length && (
          <div className="empty-cart">
            <i className="fa-regular fa-cart-shopping"></i>
            <span>No products in the cart.</span>
            <button className="return-cta" onClick={() => { navigate("/"); setShowCart(false); }}>
              RETURN TO SHOP
            </button>
          </div>
        )}

        {!!cartItems.length && (
          <>
            <div className="scrollable-cart">
              <CartItem />
            </div>
            <div className="cart-footer">
              <div className="subtotal">
                <span className="text">Subtotal:</span>
                <span className="text total">
                  R{cartSubTotal}
                </span>
              </div>
              <div className="button">
                <button className="checkout-cta" onClick={() => { navigate("/checkout"); setShowCart(false); }} >Go To Checkout</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;