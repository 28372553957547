import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { clearUserDetails, getAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';
import Cart from './Cart';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;
  static contextType = Context;
  constructor(props) {
    super(props);


    this.state = {
      collapsed: true,
      CategoryList: [],
      SubcategoryList: [],
      BrandList: [],
      isSticky: false,
      isLoggedIn: false,
      dropdownOpen: false,
      menuOpen: false,
      size: {
        width: undefined,
        height: undefined,
      },
    };
  }

  componentDidMount() {
    const { getBrands } = this.context;
    
    getBrands();
    this.handleScroll();
    const handleResize = () => {
      this.setState({
        size: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      });
    };
    window.addEventListener("resize", handleResize);
    this.handleResize = handleResize;
  }

  componentDidUpdate(prevProps, prevState) {
    const { brands } = this.context;
    
    if (prevState.BrandList !== brands) {
      this.setState({ BrandList: brands });
    }
    if (this.state.size.width > 768 && this.state.menuOpen) {
      this.setState({ menuOpen: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  }

  menuToggleHandler = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  handleScroll = () => {
    const { isSticky } = this.state;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition > 60 && !isSticky) {
      this.setState({ isSticky: true });
    } else if (scrollPosition <= 60 && isSticky) {
      this.setState({ isSticky: false });
    }

    requestAnimationFrame(this.handleScroll);
  };

  async handleLogout() {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to log out?", confirmStyles);
    if (result) {
      window.location.href = "/login";
      clearUserDetails();
    }
  }

  render() {
    //console.log(this.state)
    const { isSticky, menuOpen, size, CategoryList, SubcategoryList, BrandList } = this.state;
    const { cartCount, wishCount, showCart, setShowCart, getCategories, categories } = this.context;
    
    const parts = window.location.href.split("/");
    let lastWord;
    if (parts[parts.length - 1]) {
      lastWord = parts[parts.length - 1];
    } else {
      lastWord = parts[parts.length - 2];
    }
    const selectedBrand = BrandList.find(item => item.slug == "razer");
    //console.log(selectedBrand, lastWord, BrandList)
    return (
      <header>
        <div className="" style={{ background: "#555"  }}>
          <div className="container row mx-auto">
            <span className="col-6 py-3 hoverLinks px-0">
              <a style={{ color: selectedBrand && selectedBrand.textColour ? selectedBrand.textColour : "#fff" }} href="tel:+27102205737"><span className="number-hide">Support Hotline: </span>+27 10 220 5737</a>
            </span>
            <div className="col-6 py-3 brand-social-icons text-end px-0 brandHoverLinks">
              <a rel="noreferrer" target="_blank" href="https://www.facebook.com/RazerSouthAfrica/"><i className="fab px-1 fa-facebook"></i></a>
              <a className="fab px-1" rel="noreferrer" target="_blank" href="https://twitter.com/Razer?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg></a>
              
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/razer"><i className="fab px-1 fa-linkedin"></i></a>
              <a target="_blank" rel="noreferrer" href="https://instagram.com/razer/?hl=en"><i className="fab px-1 fa-instagram"></i></a>
            
            </div>
          </div>
        </div>

        <div className={isSticky ? "sticky mb-3 brandHeader" : "brandHeader mb-3"} style={{ background:  "#000" }}>
          <header className="container header " style={{ background: "#000" }}>
            <div className="header__content">
              <Link to="/" className="header__content__brandlogo">
                <img src="/assets/imgs/ths-razer.png" alt="logo" />
              </Link>
              <nav className={`header__content__nav ${menuOpen && size.width < 800 ? "isMenu" : ""}`} >
                <ul>
             
                  <li>
                    <Link style={{ color: "#fff" }} to="/" onClick={this.menuToggleHandler}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "#fff" }} to="/about-us" onClick={this.menuToggleHandler}>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link style={{ color: "#fff" }} to="/contact-us" onClick={this.menuToggleHandler}>
                      Contact Us
                    </Link>
                  </li>
                  
                  {getAuthToken() ?
                    <li>
                      <Link style={{ color: "#fff" }}>
                        My Account &nbsp;<small><i className="fa-light fa-angle-down" style={{ color: "#fff" }}></i></small>
                      </Link>
                      <ul>
                        <li>
                          <Link to={getAuthToken() ? "/personal-infomation" : "/login"} onClick={this.menuToggleHandler}>
                            Personal Information
                          </Link>
                        </li>
                        <li>
                          <Link to={getAuthToken() ? "/order-history" : "/login"} onClick={this.menuToggleHandler}>
                            Order History
                          </Link>
                        </li>
                        <li>
                          <Link to={getAuthToken() ? "/customer-delivery-addresses" : "/login"} onClick={this.menuToggleHandler}>
                            Delivery Details
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.handleLogout}>
                            Log Out
                          </Link>
                        </li>
                        
                      </ul>
                    </li> :
                    <li>
                      <Link style={{ color: "#fff" }} to="/login" onClick={this.menuToggleHandler}>
                        Login/Register
                      </Link>
                    </li>}
                  <NavItem>
                    <NavLink tag={Link} onClick={this.menuToggleHandler} className="text-dark cart-icon" to="/product-search"><i className="fa-regular fa-magnifying-glass ms-2" style={{ color: "#fff" }}></i></NavLink>
                  </NavItem>
                  
                  <NavItem onClick={this.menuToggleHandler}>
                    <NavLink className="text-dark cart-icon" onClick={() => setShowCart(true)}>{window.location.href.includes("/cart") ? <i className="fa-solid fa-shopping-cart ms-2" style={{ color: "#fff" }}></i> : <i className="fa-regular fa-shopping-cart ms-2" style={{ color: selectedBrand && selectedBrand.textColour }}></i>}
                      {!!cartCount && <span className="text-light">{cartCount}</span>}</NavLink>
                  </NavItem>
                </ul>
              </nav>
              <div className="header__content__toggle">
                {!menuOpen ? (
                  <i className="fa-solid fa-bars" onClick={this.menuToggleHandler}></i>
                ) : (
                  <i className="fa-solid fa-xmark" onClick={this.menuToggleHandler}></i>
                )}
              </div>
            </div>
          </header>
          
        </div>
        {showCart && <Cart setShowCart={setShowCart} />}
      </header>
    );
  }
}
const ListItem = React.forwardRef(({ className, children, title, ...props }, forwardedRef) => (
  <li>
    <NavigationMenu.Link asChild>
      <a className={classNames('ListItemLink', className)} {...props} ref={forwardedRef}>
        <div className="ListItemHeading">{title}</div>
        <p className="ListItemText">{children}</p>
      </a>
    </NavigationMenu.Link>
  </li>
));