/* eslint-disable no-unused-vars */
export const getEditId = () => {
    if (window.localStorage.getItem("EditId")) {
        return Number(window.localStorage.getItem("EditId"));
    }
    else
        return 0;
}

export const setEditId = (data) => {
    try {
        window.localStorage.setItem("EditId", data);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const getIsAdmin = () => {
    if (window.localStorage.getItem("isAdmin")) {
        return window.localStorage.getItem("isAdmin");
    }
    else
        return 0;
}

export const setIsAdmin = (data) => {
    try {
        window.localStorage.setItem("isAdmin", data);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const removeAuthToken = () => {
    if (window.localStorage.getItem("AuthToken") != null) {
        var data = JSON.parse(window.localStorage.removeItem("AuthToken"));
        return data.authToken;
    }
    else
        return null;

};

export const IsDevEnv = () => {
    const NODE_ENV = process.env.NODE_ENV || "development";

    return NODE_ENV === "development";
}

export const getCurrentDomain = () => {
    const NODE_ENV = process.env.NODE_ENV || "development"
    if (NODE_ENV === "development")
        return "https://www.triggercraft.co.za/";
    else
        //return "https://triggercraft.azurewebsites.net/";
        return "https://www.triggercraft.co.za/";
}

export const CanAddActivity = () => {
    if (window.localStorage.getItem("IsAddingActivity"))
        return parseInt(window.localStorage.getItem("IsAddingActivity")) === 0;
    else
        return true;
}

export const setAddingActivity = (state) => {
    try {
        window.localStorage.setItem("IsAddingActivity", state);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}