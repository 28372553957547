import React, { useContext } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { getAuthToken } from '../helpers/authentication';
import { Context } from '../helpers/Context';

const QuickViewModal = ({ isOpen, toggle, productData, picture, color }) => {
    const { handleAddToCart, handleAddToWishlist, handleRemoveFromWishlist, isItemInWishlist } = useContext(Context);
    //console.log(color)
    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader style={{ backgroundColor: color, color: "#fff" }} toggle={toggle}>Quick product view</ModalHeader>
            <div className="quick-view-modal text-light" style={{ backgroundColor: color }}>
                <div className="quick-view-content-left">
                    <img src={picture} alt={productData.product.title} />
                </div>
                <div className="quick-view-content-right">
                    <h2>{productData.product.title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: productData.product.shortDescription.replace(/\\n/g, '') }} />
                    <h4><strong className="price-text">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(productData.currPrice)} &nbsp;</strong>{(productData.prevPrice && productData && productData.product.sPrice && productData.product.dateSPriceEnds && new Date(productData.product.dateSPriceEnds) >= new Date()) || (productData && productData.product.sPrice && productData.product.ongoing) ? <small><del className="text-muted fs-6">R {productData.prevPrice}.00</del></small> : ""}</h4>
                    <div className="d-flex align-items-center">
                        {productData.product.stock > 0 ?
                            <button className="text-dark fw-bold" onClick={() => { handleAddToCart({ product: productData.product, productId: productData.product.id, price: productData.currPrice, status: productData.product.stock, img: productData.imageURL }, 1) }}>Add To Cart <i className="fas fa-shopping-cart ms-2"></i></button>
                            :
                            <button>Out Of Stock</button>
                        }
                        {isItemInWishlist(productData.product.id) ? <i onClick={() => handleRemoveFromWishlist(productData.product.id)} className="fa-solid fa-heart text-danger ms-3 fs-2" title="Remove From Wishlist"></i> : <i onClick={() => handleAddToWishlist({ product: productData.product, productId: productData.product.id, img: productData.imageURL, price: productData.currPrice })} className="fa-regular fa-heart fw-light ms-3 fs-2" title="Add To Wishlist"></i>}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default QuickViewModal;