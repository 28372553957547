import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Spinner } from 'reactstrap';
import { getUserDetails } from '../helpers/authentication';
import GreptchaHelper from '../helpers/GreptchaHelper';
import { showToast } from '../helpers/ToastHelper';
import { fieldRequired } from '../helpers/validation';


export default class ContactUs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      PageContent: [],
      loading: true,
      ReasonList: [],
      ReasonId: 0,
      Firstname: "",
      Lastname: "",
      EmailAddress: "",
      Mobile: "",
      Message: "",
      CaptchaToken: false,
      submitting: false
    }

    this.renderContactPage = this.renderContactPage.bind(this);
    this.submitContact = this.submitContact.bind(this);
    this.saveContact = this.saveContact.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount() {
    document.title = "Contact Us :: Razer Shop";
    this.loadData();
  }

  async loadData() {
    try {
      const response = await fetch("api/pagecontent/LoadPageContent/contact-us", {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const PageContent = await response.json();
        this.setState({ PageContent });
      } else {
        console.log(response.status + ":", response.statusText);
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const reasonsResponse = await fetch("api/contactreasons/GetAllReasons", {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (reasonsResponse.ok) {
        const data = await reasonsResponse.json();
        const ReasonList = [{ id: 0, title: "- Select Reason -" }, ...data];
        this.setState({ ReasonList, loading: false })
      } else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  renderContactPage(content, reasons) {
    return (
      <>
        <div className="container">
          <div className='row'>
            <div className="col-12 mb-3 text-light">
              <h1>Contact Us</h1>
            </div>
            {/*<div className="col-l2 mb-3 w-100" hidden={!content.banners || content.banners === ""}>*/}
            {/*    <img src="/assets/imgs/Razer_wordmark.png" className='img-fluid w-100' alt="Razer Banner" />*/}
            {/*</div>*/}
            <div className="col-md-4 text-light">
              <h3>Contact Information</h3>
              <div className="py-4 text-light contact">
                {/*<div dangerouslySetInnerHTML={{ __html: content.copy }}></div>*/}
                <div>
                  <p>
                    <span>
                      <span><strong>Product Support:&nbsp;</strong></span></span><span><span><a href="mailto:support@triggercraft.co.za"><strong>support@triggercraft.co.za</strong></a></span>
                    </span>
                  </p>
                  <p>
                    <span><strong>Support Hotline</strong></span><span><strong>:&nbsp;</strong></span><a target="_blank" href="tel:+27 10 220 5737"><strong>+27 10 220 5737</strong></a>
                  </p>
                  <div class="col-12 py-3 d-flex justify-content-evenly text-center brand-social-icons">
                    <a className="fab fa-facebook" target="_blank" href="https://www.facebook.com/RazerSouthAfrica/"></a>
                    <a className="fab px-1" target="_blank" href="https://twitter.com/Razer?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg></a>
                    <a className="fab fa-linkedin" target="_blank" href="https://www.linkedin.com/company/razer"></a>
                    <a className="fab fa-instagram" target="_blank" href="https://instagram.com/razer/?hl=en"></a>
                  </div>
                  <p><span>277 Stirrup Rd, Bridle Park AH, Midrand, 1685</span></p>
                </div>
              </div>
            </div>
            <div className="col-md-8 text-light">
              <h3>Leave us a Message</h3>
              <div className="py-4">
                <div className="row">

                  <div className="col-md-6 mb-3">
                    <label className="text-light">First Name<span className='ErrorText ms-1'>*</span><span id="errFirstname" className="ErrorText" ></span></label>
                    <Input type='text' className='form-control' value={this.state.Firstname} onChange={e => this.setState({ Firstname: e.target.value }, () => fieldRequired(this.state.Firstname, "errFirstname", "Required"))} />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-light">Last Name<span className='ErrorText ms-1'>*</span> <span id="errLastname" className="ErrorText" ></span></label>
                    <Input type='text' className='form-control ' value={this.state.Lastname} onChange={e => this.setState({ Lastname: e.target.value }, () => fieldRequired(this.state.Lastname, "errLastname", "Required"))} />

                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-light">Email Address<span className='ErrorText ms-1'>*</span> <span id="errEmail" className="ErrorText" ></span></label>
                    <Input type='text' className='form-control' value={this.state.EmailAddress} onChange={e => this.setState({ EmailAddress: e.target.value }, () => fieldRequired(this.state.EmailAddress, "errEmail", "Required"))} />

                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="text-light">Mobile <span className='ErrorText ms-1'>*</span> <span id="errMobile" className="ErrorText" ></span></label>
                    <Input type='text' className='form-control' value={this.state.Mobile} onChange={e => this.setState({ Mobile: e.target.value }, () => fieldRequired(this.state.Mobile, "errMobile", "Required"))} />

                  </div>
                  <div className="col-12 mb-3">
                    <label className="text-light">Contact Reason<span className='ErrorText ms-1'>*</span> <span id="errReason" className="ErrorText" ></span> </label>
                    <DropDownListComponent dataSource={reasons} fields={{ value: "id", text: "title" }} value={this.state.ReasonId} select={e => this.setState({ ReasonId: e.itemData.id }, () => fieldRequired(this.state.ReasonId, "errReason", "Required"))} />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="text-light">Message<span className='ErrorText ms-1'>*</span> <span id="errMessage" className="ErrorText" ></span></label>
                    <Input type='textarea' className='form-control ' row={10} value={this.state.Message} onChange={e => this.setState({ Message: e.target.value }, () => fieldRequired(this.state.Message, "errMessage", "Required"))} />

                  </div>
                  <div className="col-md-12 mb-3">
                    <GreptchaHelper HandleChangeMethod={this.handleCaptchaChange} CaptchaToken={this.state.CaptchaToken} />
                    <div id="errCaptcha" className="ErrorText"></div>
                  </div>
                  <div className="col-md-12 mb-3 text-end">
                    <Button size='sm' onClick={this.submitContact} disabled={this.state.submitting} style={{ backgroundColor: "#44D62C", border: "none", color: "#000", fontWeight: "bold" }}>Submit {this.state.submitting ? <i className='fas fa-spinner fa-spin ms-2'></i> : <i className='far fa-check-circle ms-2'></i>}</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  handleCaptchaChange(CaptchaToken) {
    this.setState({ CaptchaToken }, () => fieldRequired(this.state.CaptchaToken, "errCaptcha", "* Required"));
  }

  submitContact(e) {
    e.stopPropagation();
    let isValid = true;
    isValid &= fieldRequired(this.state.Firstname, "errFirstname", "Required");
    isValid &= fieldRequired(this.state.Lastname, "errLastname", "Required");
    isValid &= fieldRequired(this.state.EmailAddress, "errEmail", "Required");
    isValid &= fieldRequired(this.state.Mobile, "errMobile", "Required");
    isValid &= fieldRequired(this.state.Message, "errMessage", "Required");
    isValid &= fieldRequired(this.state.ReasonId, "errReason", "Required");

    if (isValid) {
      this.setState({ submitting: true });
      // ThrobbleHelper.toggleThrobble(true, "Submitting Message");
      this.saveContact();
    }
  }

  async saveContact() {

    const data = {
      UserId: getUserDetails()?.id,
      Firstname: this.state.Firstname,
      Surname: this.state.Lastname,
      ReasonId: this.state.ReasonId,
      Email: this.state.EmailAddress,
      Mobile: this.state.Mobile,
      IPAddress: "",
      Message: this.state.Message,
      DateSent: new Date()
    }

    try {
      const response = await fetch("api/contactmessages", {
        method: "POST",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      if (response.ok) {
        showToast("success", "Contact Message", "Your message was successfully submitted!")
        this.clearForm();
        // ThrobbleHelper.toggleThrobble(false);

      } else {
        showToast("error", "Contact Message", "There was an error submitting your message. Pleas try again!");
        // ThrobbleHelper.toggleThrobble(false);
        this.setState({ submitting: false, loading: false });

      }
    } catch (e) {
      console.error(e);
      showToast("error", "Contact Message", "There was an error submitting your message. Pleas try again!")
      this.setState({ submitting: false, loading: false });
      // ThrobbleHelper.toggleThrobble(false);
    }
  }

  clearForm() {
    this.setState({
      Firstname: "",
      Lastname: "",
      EmailAddress: "",
      Mobile: "",
      ReasonId: 0,
      Message: "",
      CaptchaToken: false,
      loading: false,
      submitting: false
    })
  }

  render() {

    const content = this.state.loading ? <><Spinner color='dark' size={"sm"} /> Loading...</> : this.renderContactPage(this.state.PageContent, this.state.ReasonList);
    return (
      <div>
        <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
        </div>
        <nav className="mb-3 crumbs">
          <Link to="/" className="breadcrumb-active text-light"><small>Home</small>
          </Link>
          <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
          <small className="breadcrumb-not-active">Contact Us</small>
        </nav>

        {content}
      </div>
    )
  }
}
