import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../helpers/Context';
import QuickViewModal from './QuickViewModal';

export class ProductCard extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isQuickViewOpen: false,
      quickViewProduct: null,
    };
  }

  calculateSavingsPercentage(originalPrice, discountedPrice) {
    const savingsAmount = originalPrice - discountedPrice;
    const savingsPercentage = (savingsAmount / originalPrice) * 100;
    return savingsPercentage.toFixed(0);
  };

  view = (productData) => {
    this.setState({ isQuickViewOpen: true, quickViewProduct: productData });
  };

  toggleQuickView = () => {
    this.setState({ isQuickViewOpen: !this.state.isQuickViewOpen });
  };

  handleShare = async (productLink) => {
    try {
      await navigator.share({
        title: 'Check out this product!',
        url: productLink,
      });
      //console.log('Shared successfully');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  render() {
    const { product, imageURL, prevPrice, currPrice, accentColour, mainColour, cardColour, textColour } = this.props;
    //const { product, imageURL, currPrice, accentColour, mainColour, cardColour, textColour } = this.props;
    const { handleAddToCart, handleAddToWishlist, handleRemoveFromWishlist, isItemInWishlist } = this.context;
    const { quickViewProduct, isQuickViewOpen } = this.state;
    const productData = { ...this.props }

    let image = "/assets/imgs/Razer_wordmark.png";
    //console.log(productData.imageURL)

    if (productData && productData.imageURL) {
      let images = productData.imageURL;
      if (productData.imageURL.includes("DynamicData")) images = JSON.parse(productData.imageURL);

      if (Array.isArray(images)) {
        let pic = images[0];
        if (pic.includes("DynamicData") && pic.includes("[")) {
          const parsedImage = JSON.parse(pic);
          image = "https://www.triggercraft.co.za/" + parsedImage[0];
        } else {
          image = "https://www.triggercraft.co.za/" + pic;
        }

      } else if (images.includes("https") && images.includes("[")) {
        image = JSON.parse(images.split(", ")[0])[0];
      } else if (images.includes("https") || images.includes("assets")) {
        image = images.split(", ")[0];
      } else {
        image = "https://www.triggercraft.co.za/" + images;
      }
    }
    //let prevPrice = 8099.56;
    //console.log(image, productData)
    console.log(productData.product.badge);
    return (
      <>
        
        
        <div className="card home-product h-100" >
          {prevPrice ? <div className="discount-block">-{this.calculateSavingsPercentage(prevPrice, currPrice)}% OFF</div> : (productData.product.badge ? <div className="discount-block">{productData.product.badge}</div> : "")}

          <div className="p-2 text-light wishlist">
            <i className="fas fa-share-nodes fw-light fs-5" title="Share" onClick={() => this.handleShare("/products/razer-product-view/" + productData.product.sku)}></i>
            <br />
            <i onClick={() => this.view(productData)} className="fas fa-search fw-light fs-5" title="Quick View"></i>
          </div>
          

          <Link to={"/products/razer-product-view/" + productData.product.sku} state={{ productData: productData }} className="product-image d-flex justify-content-center align-items-center" >
            <img className="img-fluid" src={image} alt="" />
          </Link>
          
          

          <div className='card-body'>

            <Link to={"/products/razer-product-view/" + productData.product.sku} state={{ productData: productData }} className="">
              <h5>{product.title}</h5>
            </Link>

          </div>
          <div className="card-footer">

            <table className="w-100">
              <tbody>
                <tr>
                  <td>
                    <div className="price-text">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(currPrice)}</div>
                    {/*<div className="sale">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(currPrice)}</div>*/}
                    {prevPrice ? <div className="sale">{new Intl.NumberFormat('en-ZA', { style: 'currency', currency: 'ZAR', }).format(prevPrice)}</div> : ""}
                  </td>
                  <td>
                    <button className="add-to-cart-btn" onClick={() => { handleAddToCart({ product, productId: product.id, price: currPrice, img: imageURL }, 1) }}>BUY</button>
                  </td>
                </tr>
              </tbody>
            </table>

            
          </div>

        </div>
        {
          quickViewProduct && (
            <QuickViewModal isOpen={isQuickViewOpen} color={cardColour && cardColour} toggle={this.toggleQuickView} productData={quickViewProduct} picture={image} />
          )
        }
      </>
    );
  }


}