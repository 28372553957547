import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { fieldRequired } from '../helpers/validation';
import { setAuthToken, setUserDetails } from '../helpers/authentication';
import { setIsAdmin } from '../helpers/Lookup';

export class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Id: 0,
      UserId: '',
      UserType: 0,
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      EmailConfirmed: false,
      CurrentPassword: '',
      LastPassword: '',
      Mobile: '',
      Communication: 0,
      Credit: 0,
      Status: 1,
      DateAdded: new Date(),
      DateModified: new Date(),
      AddedBy: 0,
      DateVerified: new Date(),
      LastLogin: new Date(),
      FailedLogins: 0,
      LockedOut: false,
      LockOutDate: new Date(),
      ResetPassword: false,
      LastIP: '',
      LoginCookie: '',
    };
    this.saveData = this.saveData.bind(this);
  }

  componentDidMount() {
    document.title = "Register :: Razer Shop";
  }

  fieldRequired = (value, errorId, errorClass) => {
    const showError = value.trim() === '';
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.style.display = showError ? 'inline' : 'none';
      errorElement.className = showError ? errorClass : '';
    }
  };

  passwordFieldRequired = (condition, errorId, errorMessage) => {
    const errorElement = document.getElementById(errorId);
    if (errorElement) {
      errorElement.style.display = condition ? 'none' : 'inline';
      errorElement.textContent = condition ? '' : errorMessage;
    }
  };

  render() {
    return (
      <div className='container mt-5 '>
        <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
        </div>
        <div className="row">
          <div className="col-md-6 mx-auto">
            {/*<div className="mb-5">*/}
            {/*  <img src="/assets/imgs/Razer_wordmark.png" className="img-fluid w-100" alt="Razer Logo" />*/}
            {/*</div>*/}
            <div className="card shadow-lg bg-dark rounded mb-3">
              <div className="card-body">
                <h3 className="text-uppercase mb-4 text-light">Register</h3>

                <FormGroup>
                  <Label className="text-light">
                    First Name
                    <span className="required-icon">*</span>
                    <span id="errFirstName" className="required-icon" />
                  </Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    onChange={(e) => {
                      this.setState({ FirstName: e.target.value });
                      this.fieldRequired(e.target.value, 'errFirstName', 'required');
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-light">
                    Last Name
                    <span className="required-icon">*</span>
                    <span id="errLastName" className="required-icon" />
                  </Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    onChange={(e) => {
                      this.setState({ LastName: e.target.value });
                      this.fieldRequired(e.target.value, 'errLastName', 'required');
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-light">
                    Email
                    <span className="required-icon">*</span>
                    <span id="errEmail" className="required-icon" />
                  </Label>
                  <Input
                    type="email"
                    bsSize="sm"
                    onChange={(e) => {
                      this.setState({ EmailAddress: e.target.value });
                      this.fieldRequired(e.target.value, 'errEmail', 'required');
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-light">
                    Password
                    <span className="required-icon">*</span>
                    <span id="errPassword" className="required-icon" />
                  </Label>
                  <Input
                    type="password"
                    bsSize="sm"
                    onChange={(e) => {
                      this.setState({ CurrentPassword: e.target.value });
                      this.fieldRequired(e.target.value, 'errPassword', 'required');
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="text-light">
                    Confirm Password
                    <span className="required-icon">*</span>
                    <span id="errConfirmPassword" className="required-icon" />
                  </Label>
                  <Input
                    type="password"
                    bsSize="sm"
                    onChange={(e) => {
                      //this.setState({ LastPassword: e.target.value === this.state.CurrentPassword });
                      this.passwordFieldRequired(e.target.value === this.state.CurrentPassword, "errConfirmPassword", "Password doesn't match");
                    }}
                  />
                  <FormText color="light">
                    Password entered here should match the one in the above password
                    field.
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Label className="text-light">
                    Mobile
                  </Label>
                  <Input
                    type="text"
                    bsSize="sm"
                    onChange={(e) => {
                      this.setState({ Mobile: e.target.value })
                    }}
                  />
                </FormGroup>

                <div className="d-flex justify-content-between mb-3">
                  <small className="align-self-end login text-light">
                    Already have account? <Link to="/login">Login</Link>
                  </small>
                  <Button style={{ backgroundColor: "#44D62C", border: "none", color: "#000", fontWeight: "bold" }} size='sm' outline onClick={this.saveData} >
                    Register &nbsp;{!this.state.loading ? <i className="fas fa-chevron-right ms-2"></i> : < i className='fas fa-spinner fa-spin me-2'></i>}
                  </Button>
                </div>
                {/*<hr />*/}
              </div>
            </div>
            {/*<div className="text-purple text-center py-3">Version: {getAppVersion()}</div>*/}
          </div>
        </div>
      </div>
    );
  }

  async saveData() {

    let isValid = true;
    isValid &= fieldRequired(this.state.FirstName, "errFirstName", " required");
    isValid &= fieldRequired(this.state.LastName, "errLastName", " required");
    isValid &= fieldRequired(this.state.EmailAddress, "errEmail", " required");
    isValid &= fieldRequired(this.state.CurrentPassword, "errPassword", " required");
    //isValid &= fieldRequired(this.state.LastPassword, "errConfirmPassword", " required");

    if (isValid) {
      this.setState({ loading: true, showError: false, showSuccess: false });

      //var bearer = 'Bearer ' + getAuthToken();
      var data = { Id: this.state.Id, UserId: this.state.UserId, UserType: this.state.UserType, FirstName: this.state.FirstName, LastName: this.state.LastName, EmailAddress: this.state.EmailAddress, EmailConfirmed: this.state.EmailConfirmed, CurrentPassword: this.state.CurrentPassword, LastPassword: this.state.LastPassword, Mobile: this.state.Mobile, Communication: this.state.Communication, Credit: this.state.Credit, Status: this.state.Status, DateAdded: this.state.DateAdded, DateModified: this.state.DateModified, AddedBy: this.state.AddedBy, DateVerified: this.state.DateVerified, LastLogin: this.state.LastLogin, FailedLogins: this.state.FailedLogins, LockedOut: this.state.LockedOut, LockOutDate: this.state.LockOutDate, ResetPassword: this.state.ResetPassword, LastIP: this.state.LastIP, LoginCookie: this.state.LoginCookie, }
      //console.log(data);
      try {
        const response = await fetch('api/userdata/registeruser', {
          method: 'POST',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          const data = await response.json();
          //this.loadData();
          this.setState({ loading: false });
          //console.log(data.item1);
          setAuthToken(data.item1, data.item2);
          setIsAdmin(data.item3);
          setUserDetails(data.item4);
          ToastUtility.show({
            title: 'Register', content: 'You have been successfully registered!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
          });

          window.location.href = "/";
        }
        else {
          this.setState({ loading: false });
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            this.props.history.push("/login");
          if (response.status === 409) {
            ToastUtility.show({
              title: 'Register', content: 'A user with that email address already exists, login / go to forgot pasword!', timeOut: 8000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
            });
          }
        }

      } catch (e) {
        console.error(e);
        this.setState({ loading: false });
        ToastUtility.show({
          title: 'Register', content: 'There was an error registering you!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  }
}