/* eslint-disable default-case */
import { ToastUtility } from "@syncfusion/ej2-notifications"
import ThrobbleHelper from "./ThrobbleHelper";

export const showToast = (type, title, content, loadMethod) => {

    setTimeout(() => {
        switch (type) {
            case "success":
                ToastUtility.show({
                    title,
                    content,
                    cssClass: "e-toast-success",
                    position: { X: "Right", Y: "Top" },
                    timeOut: 5000,
                    showCloseButton: true,
                });
                break;
            case "error":
                ToastUtility.show({
                    title,
                    content,
                    cssClass: "e-toast-danger",
                    position: { X: "Right", Y: "Top" },
                    timeOut: 5000,
                    showCloseButton: true,
                })
                break;
            case "info":
                ToastUtility.show({
                    title,
                    content,
                    cssClass: "e-toast-info",
                    position: { X: "Right", Y: "Top" },
                    timeOut: 5000,
                    showCloseButton: true,
                })
                break;
        }
        // ThrobbleHelper.toggleThrobble(false);
    }, 2500)

}