/* eslint-disable eqeqeq */
export const fieldRequired = (data, errorContainer, errorMessage) => {
    if (data === '' || data === 0 || data === null || data === undefined || data == false) {
        document.getElementById(errorContainer).innerText = errorMessage;
        return false;
    }
    else {
        document.getElementById(errorContainer).innerText = "";
        return true;
    }
};

