import { AboutUs } from "./components/AboutUs";
import CategoryPage from "./components/CategoryPage";
import CheckoutPage from "./components/CheckoutPage";
import ContactUs from "./components/ContactUs";
import CustomerDeliveryAddresses from "./components/CustomerDeliveryAddresses";
import DeliveryDetails from "./components/DeliveryDetails";
import ForgotPassword from "./components/ForgotPassword";
import Home from "./components/Home";
import { Login } from "./components/Login";
import OrderCompletion from "./components/OrderCompletion";
import OrderHistory from "./components/OrderHistory";
import { PayFastCancelled } from "./components/payfast/Cancelled";
import PayFastComplete from "./components/payfast/Complete";
import { PersonalInformation } from "./components/PersonalInformation";
import ProductSearch from "./components/ProductSearch";
import ProductView from "./components/ProductView";
import { Register } from "./components/Register";
import ResetPassword from "./components/ResetPassword";

const AppRoutes = [
    { index: true, element: <Home /> },
    //{ path: '//brands/razer', element: <Home /> },
    { path: '/categories/razer/:category', element: <CategoryPage /> },
    { path: '/products/razer-product-view/:sku', element: <ProductView /> },
    { path: '/product-search', element: <ProductSearch /> },
    { path: '/order-history', element: <OrderHistory /> },
    { path: '/personal-infomation', element: <PersonalInformation /> },
    { path: '/delivery-details/:id', element: <DeliveryDetails /> },
    { path: '/customer-delivery-addresses', element: <CustomerDeliveryAddresses /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/checkout', element: <CheckoutPage /> },
    { path: '/checkout/complete', element: <PayFastComplete /> },
    { path: '/checkout/cancel', element: <PayFastCancelled /> },
    { path: '/order-completion', element: <OrderCompletion /> },
    { path: '/login', element: <Login /> },
    { path: '/register', element: <Register /> },
    { path: '/forgot-password', element: <ForgotPassword /> },
    { path: '/reset-password/:id', element: <ResetPassword /> },
    //{ path: '/fetch-data', element: <FetchData /> }
];

export default AppRoutes;
