import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';

export default function GreptchaHelper(props) {
  const [CaptchaToken] = useState(props.CaptchaToken);
  const recaptchaRef = React.createRef();

  useEffect(() => {

    if (CaptchaToken === "")
      ResetCaptcha();
  })

  const ResetCaptcha = () => {
    //console.log(recaptchaRef.current)
    recaptchaRef.current.reset();
  }

  const handleRecaptchaChange = (value) => {
    if (props.HandleChangeMethod)
      props.HandleChangeMethod(value);
  }
  return (
    <div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Le_0BgqAAAAAGMd_okgVmxEJtmQTAmZ8ug7AzRR"
        onChange={handleRecaptchaChange}
        onExpired={handleRecaptchaChange}
      />
    </div>
  )
}
