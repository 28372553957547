import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { Context } from '../helpers/Context';
import { getCurrentDomain } from '../helpers/Lookup';
//import FixedMessenger from './FixedMessenger';
import { ProductCard } from './ProductCard';

const CategoryPage = () => {
  const [loading, setLoading] = useState(true);
  const [isDivVisible, setIsDivVisible] = useState(window.innerWidth >= 800);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [collapsedCategories, setCollapsedCategories] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [maxPrice, setMaxPrice] = useState(100000);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [sort, setSort] = useState(null);
  const [showOutOfStock, setShowOutOfStock] = useState(true);
  const [selectedOption, setSelectedOption] = useState('lowest');
  const [productsPerPage] = useState(9);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const { category } = useParams();

  let FilterCatName = category.replace(/_/g, " ");

  const handleCategoryChange = (event) => {
    const categoryId = event.target.id;
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };

  const handleDeselectAll = () => {
    setSelectedBrands([]);
    setMaxPrice(100000)
  };

  const toggleCategory = (categoryId) => {
    if (collapsedCategories.includes(categoryId)) {
      setCollapsedCategories(collapsedCategories.filter((id) => id !== categoryId));
    } else {
      setCollapsedCategories([...collapsedCategories, categoryId]);
    }
  };

  const parts = window.location.href.split("/");
  const lastWord = parts[parts.length - 1];

  const { products, brands, categories, subcategories, getProducts, getBrands, getCategories, getSubcategories } = useContext(Context);
  const selectedBrand = brands.find(item => item.slug === "razer");
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  let filteredProducts = products.filter(item => { return item.productBrandId == selectedBrand.id });

  let scategory = categories.find(c => c.slug === lastWord);
  if (scategory) {
    FilterCatName = scategory && scategory.title;
    let FilterSubcategories = subcategories ? subcategories.filter(c => c.categoryId === scategory.id).map(c => { return c.id; }) : [];
    filteredProducts = filteredProducts?.filter((product) => {
      if (product.selectedCategories !== "" || product.selectedCategories) {
        const selectedCategoriesArr = JSON.parse(product.selectedCategories);
        if (selectedCategoriesArr || Array.isArray(selectedCategoriesArr)) {
          return selectedCategoriesArr?.some(id => FilterSubcategories.includes(id));
        } else {
          return false;
        }
      } else
        return false;
    }).filter(product => selectedCategories.length === 0 || selectedCategories.some(categoryId => product.selectedCategories.includes(categoryId)));
  } else {

    scategory = subcategories.find(c => c.slug === lastWord);
    FilterCatName = scategory && scategory.title;
    try {
      filteredProducts = filteredProducts.filter((product) => {
        if (product.selectedCategories !== "" || product.selectedCategories) {
          const selectedCategoriesArr = JSON.parse(product.selectedCategories);
          return selectedCategoriesArr.includes(scategory.id);
        } else
          return false;
      }).filter(product => selectedCategories.length === 0 || selectedCategories.some(categoryId => product.selectedCategories.includes(categoryId)));
    } catch (e) {
      console.log(e);
    }
  }

  filteredProducts = filteredProducts.filter(
    (product) => product.price <= maxPrice || (product.sPrice && product.sPrice <= maxPrice)
  );

  if (selectedOption === 'lowest') {
    filteredProducts = filteredProducts.sort((a, b) => {
      const aPrice = a.sPrice || a.price;
      const bPrice = b.sPrice || b.price;
      return aPrice - bPrice;
    });
  } else if (selectedOption === 'highest') {
    filteredProducts = filteredProducts.sort((a, b) => {
      const aPrice = a.sPrice || a.price;
      const bPrice = b.sPrice || b.price;
      return bPrice - aPrice;
    });
  } else if (selectedOption === 'a-z') {
    filteredProducts = filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
  } else if (selectedOption === 'z-a') {
    filteredProducts = filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
  }

  if (showOutOfStock) {
    filteredProducts = filteredProducts.filter(item => item.stock > 0);
  } else if (!showOutOfStock) {
    filteredProducts = filteredProducts.filter(item => item);
  }

  const ProductBrandIDs = filteredProducts.map(item => { return item.productBrandId });
  const FilteredBrands = brands.filter(c => ProductBrandIDs.includes(c.id));

  filteredProducts = filteredProducts.filter((product) =>
    selectedBrands.length === 0 || selectedBrands.some((productBrandId) => product.productBrandId === productBrandId)
  );

  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const visiblePageCount = 7;

  const getStartPage = () => {
    if (currentPage <= Math.ceil(visiblePageCount / 2)) {
      return 1;
    } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
      return totalPages - visiblePageCount + 1;
    } else {
      return currentPage - Math.floor(visiblePageCount / 2);
    }
  };

  const beforePseudoStyle = {
    backgroundColor: selectedBrand && selectedBrand.accentBrandColour,
    borderRadius: "24px",
    transition: "all 0.2s",
  };

  const startPage = getStartPage();

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const toggleDiv = () => {
    setIsDivVisible(!isDivVisible);
  };

  const toggleStock = () => {
    setShowOutOfStock((prevShowOutOfStock) => !prevShowOutOfStock);
  };

  useEffect(() => {
    document.title = "Razer Categories :: Razer";
    getBrands();
    getCategories();
    getSubcategories();
    getProducts().then(() => setLoading(false));

    const handleResize = () => {
      setIsDivVisible(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [])
  //console.log(scategory)
  return (
    <>
      <div className="background-container">
      </div>
      <nav className="mb-3 crumbs text-light">
        <Link to="/" className="breadcrumb-active text-light"><small>{selectedBrand && selectedBrand.title}</small></Link>
        <span className="breadcrumb-arrow"><i className="fa-solid fa-angle-right"></i></span>
        <small className={location.pathname === "/category" ? "breadcrumb-active" : "breadcrumb-not-active"}>{FilterCatName}</small>
      </nav>

      <div className="brand-products">
        {/*<button onClick={toggleDiv} className="btn mb-3">Filters</button>*/}
        {
        //console.log(categories, scategory)
        }
        {isDivVisible && (
          <div className="product-left">
            <div className="filter-item">
              <h3 className="fw-bold text-light">FILTER BY</h3>
              <h5 className="fw-bold text-light">Product Categories</h5>
              {categories.map((category) => (category.id === scategory.id &&
                <div className="category" key={category.id}>
                  <div className="category-header d-flex justify-content-between align-items-center" onClick={() => toggleCategory(category.id)}>
                    <strong>{category.title}</strong><span className="mx-5">{collapsedCategories.includes(category.id) ? <i className="fa-solid fa-circle-minus"></i> : <i className="fa-solid fa-circle-plus"></i>}</span>
                  </div>
                  <div className={`subcategory-list ${collapsedCategories.includes(category.id) ? 'collapsed' : ''}`}>
                    {subcategories
                      .filter((sub) => sub.categoryId === category.id)
                      .map((sub) => (
                        <div className="subcategory" key={sub.id}>
                          <input type="checkbox" id={sub.id} checked={selectedCategories.includes(sub.id.toString())} onChange={(e) => handleCategoryChange(e)} />
                          <label htmlFor={sub.id}>{sub.title}</label>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>)}

        <div className="product-container row product-right col-lg-9 col-md-12 px-2">
          <h1 className="mb-4 text-uppercase fw-bold text-light">{FilterCatName}</h1>
          <div className="d-flex justify-content-between mb-4">
            <div>
              <h4 className="fw-bold text-light">{filteredProducts.length} products</h4>
            </div>
            <div className="text-end">
              <form action="#">
                <label htmlFor="sort" className="text-light">Sort:</label>
                <select name="sort" id="sort" className="brand-sort-selection--style" onChange={handleOptionChange} >
                  <option value="lowest">Price(lowest)</option>
                  <option value="highest">Price(highest)</option>
                  <option value="a-z">Title(a-z)</option>
                  <option value="z-a">Title(z-a)</option>
                </select>
              </form>
            </div>
          </div>
          {/*<hr />*/}
          {loading ? <span className="text-center text-light"><i className="fa-solid fa-spinner"></i>&nbsp; Loading...</span> : filteredProducts.length ? currentProducts.map((product) => (
            <div className="col-md-6 col-lg-4 d-flex flex-wrap justify-content-evenly mb-3" key={product.id}>
              <ProductCard
                product={product}
                id={product.id}
                status={product.stock}
                accentColour={selectedBrand.accentBrandColour}
                mainColour={selectedBrand.secondaryBrandColor}
                cardColour={selectedBrand.primaryBrandColour}
                textColour={selectedBrand.textColour}
                imageURL={product.images ? product.images.split(", ")[0] : "/assets/imgs/Razer_wordmark.png"}
                currPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.sPrice : product.price}
                prevPrice={(product.sPrice && product.dateSPriceEnds && new Date(product.dateSPriceEnds) >= new Date()) || (product.sPrice && product.ongoing) ? product.price : product.sPrice}
                title={product.title}
                description={product.shortDescription}
              />
            </div>
          )) : <p className="text-light">No Products Found</p>}

          {!loading && filteredProducts.length > productsPerPage ? (<div className="pagination-container pagination">
            <button onClick={() => handlePageChange(currentPage - 1)} style={beforePseudoStyle} disabled={currentPage === 1} className="pagination-newer">
              Previous
            </button>
            
            {Array.from({ length: Math.min(visiblePageCount, totalPages) }).map((_, index) => {
              const pageNumber = startPage + index;
              return (
                pageNumber > 0 &&
                pageNumber <= totalPages && (
                  <button
                    style={beforePseudoStyle}
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={currentPage === pageNumber}
                    className={currentPage === pageNumber ? "pagination-active ellipsis-button" : "ellipsis-button"}
                  >
                    {pageNumber}
                  </button>
                )
              );
            })}

            <button onClick={() => handlePageChange(currentPage + 1)} style={beforePseudoStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} disabled={currentPage === totalPages} className="pagination-older">
              Next
            </button>
          </div>)
            : ""}
        </div>
      </div>
      
    </>
  );
};

export default CategoryPage;