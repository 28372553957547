import React, { useContext, useEffect, useState } from 'react'
import { Context } from "../../helpers/Context";
import { BorderWidthIcon } from '@radix-ui/react-icons';

function PayFastComplete() {

    const { CheckPaymentStatus, ResetUserCart } = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState("");


    useEffect(() => {
        loadData();
    }, [loading]);

    const loadData = () => {
        CheckPaymentStatus().then(res => {
            if (res === "success")
                ResetUserCart();

            setPaymentStatus(res);
            setLoading(false);
        });

        // setTimeout(() => {
        //   
        // }, 7000)
        // console.log(paymentStatus);
    }

    const RenderSpinner = () => {
        return (
            <>
                <h3>Thank you your payment is currently being processed.<br />
                    You will shortly be notified of the order status...<br /></h3>
                <div className="spinner-border text-primary my-3" role="status" style={{ width: "100px", height: "100px", fontWeight: 800 }}>
                </div>
                <h3>Thank you for transacting with Razer powered by TriggerCraft!</h3>
            </>
        )
    }

    const RenderPaymentStatus = () => {
        return (
            paymentStatus === "success" ?
                <><i className='far fa-check-circle fa-5x mb-4 mt-3 text-success'></i>
                    <h3>Thank you, your payment has been received!</h3>
                </>
                :
                <><i className='far fa-times-circle fa-5x mb-4 mt-3 text-danger'></i>
                    <h3> There seems to be an error with your payment!<br />
                        Please try again later or contact us.</h3>
                </>)
    }

    const content = loading ? RenderSpinner() : RenderPaymentStatus();


    return (
        <>
            <div className="background-container" style={{ backgroundColor: 'black', color: 'white' }} >
            </div>
            <div className='col-md-8 mx-auto '>
                <div className="card bg-dark">
                    <div className="card-body text-center text-light">
                        {content}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayFastComplete